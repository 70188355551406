.headerWrapper {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: flex-start;
}
