.title {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.icon {
  color: var(--colors-icon-main);
  margin-right: 15px;
}

.text {
  color: var(--colors-type-main);
}
