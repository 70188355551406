.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.delimiterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.delimiterContainer .delimiter {
  color: var(--colors-type-secondary);
}

.item {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 3px;
  margin-bottom: 3px;
}
