.page {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.sidebar {
  width: 350px;
  position: sticky;
  top: 50px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
}

.content {
  width: calc(100% - 370px);
}
