.selector {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  color: var(--colors-icon-main);
}

.selector:hover {
  color: var(--colors-base-primary);
}

.caption {
  color: var(--colors-type-main);
  margin-right: 5px;
}
