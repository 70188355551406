.chartContainer {
  height: 300px;
  max-width: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.barSkeleton {
  position: absolute;
  display: flex;
  align-items: flex-end;
}
