.container {
  width: 200px;
  display: flex;
  flex-direction: column;
}

.row {
  cursor: pointer;
}

.additional {
  border-top: 1px solid var(--colors-attribute-divider-light);
}
