.container {
  display: flex;
  width: 100%;
  height: 290px;
  flex-direction: column;
  padding: 20px 30px 30px 30px;
}

.chartContainer {
  height: 60px;
  max-width: 500px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
}
