.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.field {
  margin-left: 50px;
}

.block {
  padding-bottom: 15px;
}

.block:hover {
  z-index: 2;
}
