.cardWrapper {
  z-index: 0;
  position: relative;
  transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.cardWrapper:hover {
  z-index: 1;
}

.cardWrapper.selected {
  z-index: 1;
}

.card {
  display: flex;
  flex-direction: row;
  min-height: 50px;
  border-radius: 4px;
  background: var(--colors-background-level-3);
  transition: box-shadow 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
}

.card.selected {
  box-shadow: var(--shadows-tile-default);
  background: var(--colors-background-level-1);
}

.card:hover {
  box-shadow: var(--shadows-tile-hover);
  background: var(--colors-background-level-1);
}

.content {
  display: flex;
  flex-direction: column;
  padding: 15px 0 15px 15px;
  flex-grow: 1;
  min-width: 0;
}

.title {
  display: block;
  user-select: none;
}

.description {
  display: block;
  color: var(--colors-type-secondary);
  user-select: none;
  margin-top: 5px;
}

.selectContainer {
  display: flex;
  width: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.radio {
  pointer-events: none;
}
