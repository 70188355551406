.tooltip {
  padding-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.header {
  display: flex;
  padding-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  height: 20px;
  align-items: center;
  position: relative;
}

.icon {
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 2px;
}

.key {
  color: var(--colors-type-secondary);
  display: flex;
  flex-basis: 150px;
  margin-left: 16px;
}

.value {
  display: flex;
  flex-basis: 50px;
}

