.item {
  display: flex;
  flex-direction: row;
  width: 300px;
}

.delimiter {
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
  align-items: center;
  text-align: center;
}

.delimiter .text {
  color: var(--colors-type-secondary);
}

.field {
  display: flex;
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
}
