.row {
  height: 60px;
  border-bottom: 1px solid var(--colors-attribute-divider-light);
}

.row:last-child {
  height: 80px;
  border-bottom: none;
}

.row:last-child .cell {
  padding-bottom: 40px;
}

.cell {
  padding: 15px;
  border: none;
}

.cell.withBorder {
  border-right: 1px solid var(--colors-attribute-divider-light);
}

.cell:last-child {
  padding-right: 30px;
}
