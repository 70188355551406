.description {
  padding-left: 50px;
  padding-bottom: 50px;
}

.content {
  margin-bottom: 15px;
  width: 250px;
  color: var(--colors-type-secondary);
}

.content .main {
  color: var(--colors-type-main);
}

.content .group.spaced {
  display: block;
  margin-bottom: 5px;
}

.select {
  display: inline-flex;
  height: 20px;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  color: var(--colors-type-secondary);
}

.select:hover .icon {
  color: var(--colors-type-main);
}

.select .text {
  color: var(--colors-type-main);
  margin-right: 3px;
}

.button {
  margin-left: -5px;
}

.icon {
  margin-top: 2px;
  color: var(--colors-icon-main);
}
