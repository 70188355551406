.container {
  display: flex;
  flex-direction: column;
  background: var(--colors-background-level-1);
  border: 1px solid var(--colors-attribute-divider-dark);
  border-radius: 4px;
  transition: all 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  padding-bottom: 10px;
}

.container:hover,
.container.active {
  box-shadow: var(--shadows-tile-hover);
  border: 1px solid rgba(20, 27, 36, 0.2);
}

.container:hover .container .activeIcon,
.container.active .container .activeIcon {
  color: var(--colors-base-primary);
}

.container.active {
  box-shadow: var(--shadows-tile-default);
}

.container:active,
.container:focus {
  outline: none;
}

.panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  height: 30px;
}

.title {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--colors-icon-main);
}

.text {
  color: var(--colors-type-main);
  display: inline-block;
  margin-left: 5px;
}

.activeIcon {
  display: inline-block;
  height: 20px;
  width: 20px;
  color: var(--colors-icon-main);
  cursor: pointer;
}
