.buttonContainer {
  width: 100%;
  max-width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.button {
  display: inline-flex;
  border-radius: 4px;
  align-items: center;
}

.active {
  background: rgba(50, 92, 164, 0.07);
}

.buttonActive {
  pointer-events: none;
}
