.header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: var(--colors-background-level-2);
  background-image: linear-gradient(89.38deg, rgba(10, 14, 18, 0.03) 0.53%, rgba(10, 14, 18, 0) 99.47%);
}

.static {
  position: static;
}

.spacer {
  height: 50px;
  width: 100%;
}
