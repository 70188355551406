.container {
  padding-left: 30px;
  width: calc(100% - 90px);
}

.name {
  display: block;
  margin-bottom: 2px;
  line-height: 30px;
}

.description {
  display: block;
  color: var(--colors-type-secondary);
}

.preloaderDescription {
  width: 80%;
}
