.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
  color: var(--colors-base-error);
}

.icon {
  display: block;
  margin-bottom: 10px;
}
