.panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;
}

.button {
  display: inline-flex;
  height: 20px;
  flex-direction: row;
  align-items: center;
  color: var(--colors-type-secondary);
  cursor: pointer;
}

.button:hover {
  color: var(--colors-type-main);
}

.button .icon {
  margin-right: 5px;
}
