.header {
  border: none;
}

.header .headerCell:last-child {
  padding-right: 30px;
}

.headerCell {
  height: 50px;
  padding: 15px;
  color: var(--colors-type-secondary);
  border: none;
  border-spacing: 0;
  border-collapse: collapse;
  border-bottom: 1px solid var(--colors-attribute-divider-dark);
  user-select: none;
}

.headerCell.active {
  color: var(--colors-type-main);
}

.headerCell.sticky {
  background: none;
  border-bottom: none;
}

.headerCell.clickable {
  cursor: pointer;
}

.headerCell.last {
  padding-right: 30px;
}

.content {
  display: flex;
  align-items: center;
  height: 20px;
}

.stuck {
  display: inline-block;
}
