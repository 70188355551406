.cell {
  display: flex;
  align-items: center;
  position: relative;
}

.title {
  display: inline-block;
  margin-left: 65px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gauge {
  position: absolute;
  left: 10px;
  height: 40px;
  width: 40px;
  top: 50%;
  transform: translateY(-50%);
}
