.container {
  display: flex;
  width: 100%;
  min-height: 500px;
  flex-direction: column;
}

.error {
  min-height: 550px;
}
