.container {
  display: flex;
  flex-direction: column;
  background: var(--colors-background-level-1);
  box-shadow: var(--shadows-tile-default);
  margin-top: 20px;
  margin-bottom: 50px;
}

.sticky {
  top: 50px;
}

.placeholder {
  min-height: calc(100vh - 120px);
}
