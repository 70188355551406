.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  width: 100%;
  height: 120px;
  padding: 60px 30px 40px 50px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-end;
}

.content {
  overflow: hidden;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.itemContainer {
  height: 100%;
  width: 100%;
}
