.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px solid var(--colors-attribute-divider-dark);
}

.gridItem {
  width: 100%;
  border-bottom: 1px solid var(--colors-attribute-divider-light);
}

@media (min-width: 1200px) {
  .gridItem {
    width: 50%;
    border-bottom: 1px solid var(--colors-attribute-divider-light);
  }

  .gridItem:nth-child(odd) {
    border-right: 1px solid var(--colors-attribute-divider-light);
  }
}
