.container {
  height: 50px;
}

.panel {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.text {
  color: var(--colors-type-secondary);
}

.preloaderLast {
  margin-left: 4px;
}
