.empty {
  color: var(--colors-type-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
}

.item {
  cursor: pointer;
}
