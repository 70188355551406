.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

@media (max-width: 1420px) {
  .singleton {
    min-width: 0;
  }
}

.singleton {
  flex-grow: 1;
  margin-right: 20px;
}

.singleton:last-child {
  margin-right: 0;
}
