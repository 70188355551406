.container {
  display: flex;
  flex-direction: column;
  width: 250px;
  max-height: 320px;
}

.header {
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: 1px solid var(--colors-attribute-divider-dark);
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  min-height: 0;
}

.item {
  cursor: pointer;
}

.empty {
  color: var(--colors-type-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
}
