.bar {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  z-index: 1;
  background: linear-gradient(90deg, #e0e2e5 0%, #eceeef 100%);
}

.cell {
  padding-left: 20px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
