@import-normalize; /* bring in normalize.css styles */
@import '~@piano-dc/atoms/colors/colors.css';
@import '~@piano-dc/atoms/shadows/shadows.css';

@font-face {
  font-family: Graphik;
  src: url('~@piano-dc/atoms/typography/Graphik-Light-Web.woff2')
      format('woff2'),
    url('~@piano-dc/atoms/typography/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url('~@piano-dc/atoms/typography/Graphik-Regular-Web.woff2')
      format('woff2'),
    url('~@piano-dc/atoms/typography/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url('~@piano-dc/atoms/typography/Graphik-Medium-Web.woff2')
      format('woff2'),
    url('~@piano-dc/atoms/typography/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-Black.otf')
    format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-Bold.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-ExtraLight.otf')
    format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('~@piano-dc/atoms/typography/SourceCodePro-Semibold.otf')
    format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nitti Mono';
  src: url('./fonts/Nitti-Normal.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nitti Mono';
  src: url('./fonts/Nitti-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Graphik, -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    Arial, sans-serif, 'Apple Color Emoji';
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--colors-background-level-2);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--colors-base-accent);
  text-decoration: none;
}

a:active,
a:visited {
  color: var(--colors-base-accent);
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
