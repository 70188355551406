.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 90px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  position: relative;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  height: 90px;
  align-items: center;
  flex-grow: 0;
  transition: box-shadow 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  z-index: 2;
}

.header.scrolled {
  box-shadow: var(--shadows-navigation);
  background: linear-gradient(
    180deg,
    #fbfcfc 0%,
    rgba(251, 252, 252, 0.7) 100%
  );
  backdrop-filter: blur(20px);
}

.icon {
  display: flex;
  height: 50px;
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--colors-icon-main);
}

.iconActive {
  cursor: pointer;
}

.iconActive:hover {
  color: var(--colors-base-primary);
}

.headerContent {
  display: flex;
  height: 50px;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}
