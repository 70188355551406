.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: var(--colors-background-level-2);
  padding: 10px;
}

.content {
  width: 740px;
  height: 380px;
  background: var(--colors-background-level-2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
}

.content .welcome {
  margin-top: 40px;
}

.content .explanation {
  color: var(--colors-type-secondary);
  display: block;
  width: 450px;
  text-align: center;
  margin-top: 15px;
}

.footer {
  display: flex;
  background: var(--colors-background-level-2);
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.doc {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.docIcon {
  margin-right: 10px;
  color: var(--colors-icon-main);
}

.docLink {
  color: var(--colors-base-accent);
  display: inline;
  text-decoration: none;
  margin-left: 5px;
}

.docLink:active,
.docLink:visited {
  color: var(--colors-base-accent);
}
