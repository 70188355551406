.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.singleton {
  min-width: 0;
  flex-grow: 1;
  flex-basis: 100px;
  margin-right: 20px;
}

.singleton:last-child {
  margin-right: 0;
}

.singleton.extended {
  flex-basis: 150px;
}

.description {
  color: var(--colors-type-secondary);
}

.value {
  color: var(--colors-type-main);
}

.descriptionPreloader {
  width: 100%;
}

.descriptionPreloader.second {
  width: 85%;
}

.descriptionPreloader.third {
  width: 77%;
}
