.backdrop {
  position: fixed;
  z-index: -1;
  height: 120px;
  background: linear-gradient(
    89.38deg,
    rgba(10, 14, 18, 0.03) 0.53%,
    rgba(10, 14, 18, 0) 99.47%
  );
  top: 50px;
  left: 0;
  right: 0;
}

.errorContainer {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  align-items: center;
  justify-content: center;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error .row {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.error .icon {
  margin-bottom: 25px;
}

.onboarding {
  z-index: 10005;
}
