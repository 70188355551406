@import '~@piano-dc/atoms/typography/typography.css';
@import '~@piano-dc/atoms/colors/colors.css';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.containerLarge {
  max-width: 325px;
}

.containerLarge .title {
  height: 25px;
}

.containerSmall {
  max-width: 175px;
}

.dotBadge {
  border-radius: 50%;
  position: absolute;
  top: 8px;
  transform: translateY(-50%);
  left: -12px;
}

.dotBadgeLarge {
  width: 5px;
  height: 5px;
}

.dotBadgeSmall {
  width: 4px;
  height: 4px;
  left: -10px;
}

.title {
  color: var(--colors-type-secondary);
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
}

.content {
  margin-bottom: 18px;
}

.preloader {
  width: 80%;
}

.preloaderValue {
  width: 50%;
}
