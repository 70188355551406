.container {
  overflow-x: hidden;
}

.items {
  display: flex;
  flex-direction: row;
  width: 500px;
}

.item {
  width: 250px;
  height: 100%;
  overflow: hidden;
}
