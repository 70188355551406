.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
}

.icon {
  display: inline-block;
  overflow: hidden;
}
