.sticky {
  top: 0;
  position: fixed;
  z-index: 3;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7) 100%);
  backdrop-filter: blur(20px);
  box-shadow: var(--shadows-tile-default);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
