.content {
  width: 250px;
  margin-left: 50px;
  flex-grow: 1;
  padding-bottom: 50px;
}

.groupTitle {
  margin-bottom: 25px;
  display: block;
}
